/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentDto } from '../models/CommentDto';
import type { CreateCommentDto } from '../models/CreateCommentDto';
import type { CreateGiftBody } from '../models/CreateGiftBody';
import type { CreateGiftResponse } from '../models/CreateGiftResponse';
import type { CreateVideoFromImagesDto } from '../models/CreateVideoFromImagesDto';
import type { Gift } from '../models/Gift';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { TokenResponse } from '../models/TokenResponse';
import type { UpdateDto } from '../models/UpdateDto';
import type { UpdateTypeDto } from '../models/UpdateTypeDto';
import type { VerifyBody } from '../models/VerifyBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GiftService {
    /**
     * @returns CreateGiftResponse
     * @throws ApiError
     */
    public static giftControllerCreate({
        requestBody,
    }: {
        requestBody: CreateGiftBody,
    }): CancelablePromise<CreateGiftResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gift',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Gift
     * @throws ApiError
     */
    public static giftControllerGetAll(): CancelablePromise<Array<Gift>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gift',
        });
    }
    /**
     * @returns any A CSV file with gifts and their status
     * @throws ApiError
     */
    public static giftControllerExport({
        domain,
    }: {
        /**
         * The domain to use for the gift links
         */
        domain: any,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gift/export',
            query: {
                'domain': domain,
            },
        });
    }
    /**
     * @returns TokenResponse
     * @throws ApiError
     */
    public static giftControllerVerify({
        requestBody,
    }: {
        requestBody: VerifyBody,
    }): CancelablePromise<TokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gift/verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Gift
     * @throws ApiError
     */
    public static giftControllerGet({
        id,
    }: {
        id: string,
    }): CancelablePromise<Gift> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gift/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns Gift
     * @throws ApiError
     */
    public static giftControllerUpdate({
        id,
        formData,
    }: {
        id: string,
        formData: UpdateDto,
    }): CancelablePromise<Gift> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gift/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @returns SuccessResponse
     * @throws ApiError
     */
    public static giftControllerDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gift/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static giftControllerCreateVideoFromImages({
        formData,
    }: {
        formData: CreateVideoFromImagesDto,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gift/createVideoFromImages',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @returns Gift
     * @throws ApiError
     */
    public static giftControllerUpdateType({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateTypeDto,
    }): CancelablePromise<Gift> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gift/{id}/type',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CommentDto
     * @throws ApiError
     */
    public static giftControllerAddComment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CreateCommentDto,
    }): CancelablePromise<CommentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gift/{id}/comment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CommentDto
     * @throws ApiError
     */
    public static giftControllerGetComments({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<CommentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gift/{id}/comments',
            path: {
                'id': id,
            },
        });
    }
}
